<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="portfolioTree.strategy && !isInactive">
    <b-row class="mt-1 mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.TRACK_CURRENCY' })"
          class="no-padding-left mb-0"
          label-cols="3"
          content-cols="9"
          label-class="pr-3"
          label-align="left"
        >
          <b-form-input
            v-model="portfolioTree.strategy.currency"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.PORTFOLIO_CURRENCY' })"
          class="no-padding-left mb-0"
          label-cols="3"
          content-cols="9"
          label-class="pr-3"
          label-align="left"
        >
          <b-form-input
            v-model="portfolioTree.toCurrency"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.FX_CONVERSION.FX_CONVERSION' })"
          class="no-padding-left mb-0"
          label-cols="3"
          content-cols="9"
          label-class="pr-3"
          label-align="left"
        >
          <StandardFormDropdown
            :menu-class="{ shadow: true }"
            :disabled="disabled || isConstituentPortfolio"
          >
            <template #button-content>
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: portfolioTreeFxType }) }}
            </template>
            <b-dropdown-item
              v-for="option of fxConversionTypes"
              :key="option"
              :active="portfolioTreeFxType === option"
              @click.stop="portfolioTreeFxType = option"
            >
              {{ option }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="translate({ path: 'MODALS.EDIT_WEIGHTING.REBALANCING_COST' })"
          content-cols="6"
          label-cols="6"
          label-align="left"
        >
          <b-input-group :append-html="rebalancingCostAppend">
            <b-form-input
              v-if="isRootCustom || isParentCustom"
              v-model="backtestRebalancingCost"
              disabled
              class="text-right ml-1"
            />
            <b-form-input
              v-else
              v-model="portfolioTree.rebalancingCost"
              :disabled="disabled || isConstituentPortfolio || isStartFreshPortfolio"
              number
              class="text-right ml-1"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="replication-cost"
          :label="translate({ path: 'MODALS.EDIT_WEIGHTING.REPLICATION_COST' })"
          content-cols="6"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          data-testid="replication-post"
        >
          <b-input-group append="% p.a.">
            <b-form-input
              v-if="isRootCustom || isParentCustom"
              v-model="backtestReplicationCost"
              disabled
              class="text-right"
            />
            <b-form-input
              v-else
              v-model="portfolioTree.replicationCost"
              :disabled="disabled || isConstituentPortfolio || isStartFreshPortfolio"
              number
              class="text-right"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <i18n-t
      keypath="MODALS.STATIC_DATA_MODAL.TRACK_NOT_FOUND"
      tag="div"
    >
      <template #email>
        <a :href="`mailto:${clientServicesEmail}`"> {{ clientServicesEmail }} </a>
      </template>
    </i18n-t>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { IPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import { FxConversion } from '@/constants/FxConversion';
import useTranslation from '@/composables/useTranslation';
import { StyleConstants } from '@/constants/StyleConstants';
import useFxConversion from '@/composables/useFxConversion';
import usePortfolioTree from '@/composables/usePortfolioTree';
import { WeightingTypeConstants } from '@/types/portfolio/AllocationWeightingConstants';
import { findParentSubportfolio, isConstituentPortfolioFn, isStartFreshPortfolioFn } from '@/utils/portfolioTree';

export default defineComponent({
  name: 'StrategyStaticData',
  props: {
    portfolioTree: {
      type: Object as PropType<IPortfolioTreeStrategy>,
      required: true,
    },
    /**
     * If true, disable all inputs in this component
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inheritedFrom: {
      type: String,
      required: false,
    },
    isInactive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { translate } = useTranslation();
    const { fxType } = useFxConversion();
    const { masterPortfolioTree } = usePortfolioTree();
    const isConstituentPortfolio = isConstituentPortfolioFn(masterPortfolioTree);
    const isStartFreshPortfolio = isStartFreshPortfolioFn(masterPortfolioTree);

    const widthOfReplicationCostSuffix = 37.8167;
    const rebalancingCostAppend = `<span style="width: ${widthOfReplicationCostSuffix}px">%</span>`;

    /**
     * Text to use for the inherited type
     * On tracks of style Alpha, we want them to always be 'Not converted'
     * And other style, to use the fxType of the root portfolio
     */
    const inheritedType = computed(() => {
      // Text to show when strategy is alpha
      const alpha = `${translate({
        path: 'GLOBAL.FX_CONVERSION.NOT_CONVERTED',
      })} - ${translate({
        path: 'GLOBAL.STYLE_CONSTANTS.ALPHA',
      })}`;

      // Determine when to show alpha
      const fxTypeToShow = props.portfolioTree.strategy?.style === StyleConstants.ALPHA ? alpha : fxType.value;

      return `${translate({ path: 'MODALS.STATIC_DATA_MODAL.INHERITED' })} ${translate({
        path: 'MODALS.GENERAL.FROM',
      })} ${props.inheritedFrom} (${translate({
        path: 'GLOBAL.FX_CONVERSION',
        item: fxTypeToShow,
      })})`;
    });

    /**
     * List of options for the Fx Conversion dropdown
     */
    const fxConversionTypes = computed(() => {
      const fxTypes = Object.values(FxConversion).map((type) =>
        translate({ path: 'GLOBAL.FX_CONVERSION', item: type }),
      );
      return [inheritedType.value].concat(fxTypes);
    });

    /**
     * Fx Conversion type visible by the user.
     * When the Fx conversion is supposed to be inherited, we use the inheritedType
     * On a new selection of Fx Conversion, we want to check if the selected option matches the inheritedType
     * then we set the isInherited to true and use the root fxType
     */
    const portfolioTreeFxType = computed({
      get() {
        if (props.portfolioTree.isInherited) return inheritedType.value;
        return props.portfolioTree.fxType ?? '';
      },
      set(newVal: string) {
        const matchesInheritedType = newVal === inheritedType.value;
        // eslint-disable-next-line vue/no-mutating-props
        props.portfolioTree.isInherited = matchesInheritedType;
        // eslint-disable-next-line vue/no-mutating-props
        props.portfolioTree.fxType = matchesInheritedType ? fxType.value : (newVal as FxConversion);
      },
    });

    /**
     * Last backtest rebalancing cost to show in the strategy static data
     */
    const backtestRebalancingCost = ref<number | undefined>();
    watch(
      () => props.portfolioTree.backtestRebalancingCosts,
      (newCost) => {
        if (newCost && newCost.length) {
          // Only show the last item in the list
          // TODO: When backtest2 is done WAA-4772, remove the '* 100' to the costs. Since the weights and cost will be stored in percentages in DB
          backtestRebalancingCost.value = newCost[newCost.length - 1] * 100;
        }
      },
      { immediate: true },
    );

    /**
     * Last backtest replication cost to show in the strategy static data
     */
    const backtestReplicationCost = ref<number | undefined>();
    watch(
      () => props.portfolioTree.backtestReplicationCosts,
      (newCost) => {
        if (newCost && newCost.length) {
          // Only show the last item in the list
          // TODO: When backtest2 is done WAA-4772, remove the '* 100' to the costs. Since the weights and cost will be stored in percentages in DB
          backtestReplicationCost.value = newCost[newCost.length - 1] * 100;
        }
      },
      { immediate: true },
    );

    /**
     * Is master portfolio of weighting type custom
     */
    const isRootCustom = computed(() => {
      if (!masterPortfolioTree.value) {
        return false;
      }
      return (
        masterPortfolioTree.value.allocation[masterPortfolioTree.value.portfolioId].weighting.type ===
        WeightingTypeConstants.CUSTOM
      );
    });

    /**
     * Is parent of portfolioTree is of weighting type custom (Only possible if linked portfolio)
     */
    const isParentCustom = computed(() => {
      if (masterPortfolioTree.value) {
        const parentOfPinnedItem = findParentSubportfolio(
          masterPortfolioTree.value.portfolioTree,
          props.portfolioTree.portfolioTreeId,
        );

        if (parentOfPinnedItem) {
          return (
            masterPortfolioTree.value.allocation[parentOfPinnedItem.portfolioTreeId].weighting.type ===
            WeightingTypeConstants.CUSTOM
          );
        }
      }

      return false;
    });

    const clientServicesEmail = import.meta.env.VITE_CLIENT_SERVICES_EMAIL;

    return {
      fxConversionTypes,
      portfolioTreeFxType,
      rebalancingCostAppend,
      translate,
      backtestRebalancingCost,
      backtestReplicationCost,
      isRootCustom,
      isParentCustom,
      isConstituentPortfolio,
      isStartFreshPortfolio,
      clientServicesEmail,
    };
  },
});
</script>
<style scoped>
.no-padding-left :deep(.form-row .col) {
  padding-left: 0;
}
</style>
