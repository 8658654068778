import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import {
  getUserInfo,
  getTeamInfo,
  getSubTeams,
  getTeamParameters,
  updateTeamParameters,
  IUserInfoResponse,
  IUpdateUser,
  ITeamResponse,
  ITeamParameter,
  IUpdateTeamParameter,
  ISubTeam,
} from '@/api-v2/web/user';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import useUser from '../useUser';
import { defineMutation } from './defineMutation';
import { chainEnabled } from '@/utils/queries';
import { UserModule } from '@/store/barrel';
import { isAxiosError } from 'axios';
import usePremialabQuery from './usePremialabQuery';

const keys = {
  all: () => [{ scope: 'user' }] as const,
  user: () => [{ ...keys.all()[0], entity: 'user' }] as const,
  teamInfo: () => [{ ...keys.all()[0], entity: 'team-info' }] as const,
  teamParameters: () => [{ ...keys.all()[0], entity: 'team-parameters' }] as const,
  subteams: () => [{ ...keys.all()[0], entity: 'sub-teams' }] as const,
};

export function useUserQuery(options: VQQueryOptions<IUserInfoResponse> = {}) {
  return useQuery({
    ...options,
    queryKey: keys.user(),
    queryFn: () => getUserInfo(),
    // Do not refetch if the error is HTTP 4xx.
    // See https://github.com/TanStack/query/issues/5988
    refetchOnWindowFocus: (query) => {
      const err = query.state.error;
      if (err == null || !isAxiosError(err)) {
        return true;
      }

      const status = err.response?.status;
      if (status == null) {
        return true;
      }

      return !(status >= 400 && status < 500);
    },
    retry(failureCount, error) {
      // Do not retry if this is a 4xx. We rely on this query to check if the
      // user is authenticated or blocked. Retrying won't make any difference
      // but make user stuck in pending page longer.
      if (isAxiosError(error)) {
        const status = error.response?.status;
        if (status != null && status >= 400 && status < 500) {
          return false;
        }
      }

      return failureCount < 3;
    },
  });
}

export function useTeamInfo(options: VQQueryOptions<ITeamResponse> = {}) {
  return useQuery(keys.teamInfo(), ({ signal }) => getTeamInfo(signal), options);
}

export function useTeamParameters(options: VQQueryOptions<ITeamParameter> = {}) {
  return useQuery(keys.teamParameters(), ({ signal }) => getTeamParameters(signal), options);
}

/**
 * Get a map of user ID -> user that has the same team with the current
 * authenticated user
 */
export function useListTeamMembers(options: VQQueryOptions = {}) {
  const enabled = chainEnabled(options.enabled);

  return usePremialabQuery(
    useQuery({
      ...options,
      queryKey: keys.teamInfo(),
      queryFn: ({ signal }) => getTeamInfo(signal),
      select(data: ITeamResponse) {
        return new Map(data.users.map((user) => [user.id, user]));
      },
      enabled,
    }),
    enabled,
  );
}

export const useUpdateUserMutation = defineMutation({
  mutationFn: (data: IUpdateUser) => UserModule.UpdateUserInfo(data),
  invalidateCache(client) {
    return client.invalidateQueries(keys.user());
  },
});

export const useUpdateTeamParameters = defineMutation({
  mutationFn: (data: IUpdateTeamParameter) => updateTeamParameters(data),
  invalidateCache(client) {
    return client.invalidateQueries(keys.teamParameters());
  },
});

export function useSubTeamList(options: VQQueryOptions<ISubTeam[]> = {}) {
  const { user } = useUser();

  return useQuery(keys.subteams(), () => getSubTeams(), {
    ...options,
    // The sub-team is very hard-coded so we don't really need to refresh this list
    staleTime: Number.POSITIVE_INFINITY,
    enabled: chainEnabled(
      options.enabled,
      import.meta.env.VITE_CLIENT === 'Premialab', // only sub-teams supported on core platform
      computed(() => user.value != null && user.value.team.isParentTeam),
    ),
  });
}
