import usePortfolioTree from '@/composables/usePortfolioTree';
import { Status } from '@/constants/Status';
import { PortfolioModule } from '@/store/barrel';
import {
  IPortfolioTreeSubportfolio,
  isPortfolioTreeSubportfolio,
  IPortfolioTreeStrategy,
  IPortfolioTree,
  isPortfolioTreeStrategy,
  IPortfolioTreeChildType,
  IPortfolioTreeStrategyStaticInfo,
} from '@/types/IPortfolioTree';
import { ISidebarRow } from '@/types/ISidebarRow';
import { computed, ComputedRef, Ref, set, unref, watch } from 'vue';
import { cloneDeep, isEqual } from 'lodash';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { Currency } from '@/constants/Currency';
import { FactorConstants } from '@/constants/FactorConstants';
import { FxConversion } from '@/constants/FxConversion';
import { Region } from '@/constants/Region';
import { StyleConstants } from '@/constants/StyleConstants';
import { PortfolioType } from '@/types/portfolio';
import { IStrategy } from '@/types/strategy';
import { StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { AddStrategySubset } from '@/views/modals/AddStrategyToPortfolioModal/AddStrategySubset';
import { WeightingTypeConstants } from '@/types/portfolio/AllocationWeightingConstants';
import useStrategyMask from '@/composables/useStrategyMask';
import { IFactsheetDatum } from '@/types/IFactsheetData';
import { useIndexUniqueIdentifier, usePortfolioTreeDraftIdentifiers } from '@/composables/useCorrectIdentifier';
import { v4 as uuidv4 } from 'uuid';
import { AllocationDTO } from '@/types/portfolio/AllocationDTO';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { RebalancingMonthlyMonthlyReturnInterval } from '@/constants/RebalancingMonthlyMonthlyReturnInterval';
import { LeverageTypeConstants } from '@/types/portfolio/AllocationLeverageConstants';
import { RebalancingTypeConstants, RebalancingQuarterlyMonths } from '@/types/portfolio/AllocationRebalancingConstants';
import useRegimeAnalysisState from '@/composables/useRegimeAnalysisState';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import usePeriod from '@/composables/usePeriod';
import useFxConversion from '@/composables/useFxConversion';
import { usePortfolioTreeTrackData } from '@/composables/queries/usePortfolioTreeData';
import { Period } from '@/types/period';
import { useStrategyTrack } from '@/composables/queries/useStrategyData';
import { ITrackQuery } from '@/types/ITrackQuery';
import { chainEnabled } from './queries';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { isStrategy } from './strategy';
import usePinned from '@/composables/usePinned';
import { MaybeRef } from '@vueuse/shared';
import { useRouteRef } from '@/composables/useRouter';
import useRouteChecks from '@/composables/useRouteChecks';

export const findParentSubportfolio = (
  portfolioTree: IPortfolioTreeSubportfolio,
  portfolioTreeId: string,
): IPortfolioTreeSubportfolio | undefined => {
  // root portfolio doesn't have a parent
  if (portfolioTree.portfolioTreeId === portfolioTreeId) return portfolioTree;
  for (const component of portfolioTree.components) {
    // if the component is the one we're looking for, return the parent
    if (component.portfolioTreeId === portfolioTreeId) return portfolioTree;
    if (isPortfolioTreeSubportfolio(component)) {
      const parentPortfolio = findParentSubportfolio(component, portfolioTreeId);
      if (parentPortfolio) return parentPortfolio;
    }
  }
};

/**
 * Find all the ancestors of an item in the portfolio tree
 */
export const findAllAncestors = async (portfolioTree: IPortfolioTreeSubportfolio, portfolioTreeId: string) => {
  const pinnedItemParentsMap = new Map<string, IPortfolioTreeSubportfolio>();

  let idToFind = portfolioTreeId;
  // While we have not reached the master portfolio tree, keep looking for the next parent
  while (idToFind !== portfolioTree.portfolioTreeId) {
    const parentOfPinnedItem = findParentSubportfolio(portfolioTree, idToFind);
    if (!parentOfPinnedItem) break;

    pinnedItemParentsMap.set(parentOfPinnedItem.portfolioTreeId, parentOfPinnedItem);
    // New id to find its parent
    idToFind = parentOfPinnedItem.portfolioTreeId;
  }

  return pinnedItemParentsMap;
};

/**
 * Get tree ids of all children and grandchildren.
 * Used to pin all active below a subportfolio
 * @param portfolioTree
 */
export const getAllDescendantIds = (
  portfolioTree: IPortfolioTreeSubportfolio,
  ids: { key: string; strategyCode?: string }[] = [],
  includeSubportfolios = true,
): { key: string; strategyCode?: string }[] => {
  for (const component of portfolioTree.components) {
    if (isPortfolioTreeStrategy(component)) {
      ids.push({ key: component.portfolioTreeId, strategyCode: component.strategy?.code });
      continue;
    }

    if (isPortfolioTreeSubportfolio(component)) {
      if (includeSubportfolios) ids.push({ key: component.portfolioTreeId });
      getAllDescendantIds(component, ids);
    }
  }
  return ids;
};

export const findTreeComponent = (
  portfolioTree: IPortfolioTreeSubportfolio,
  portfolioTreeIdToFind: string,
): IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | undefined => {
  if (portfolioTree.portfolioTreeId === portfolioTreeIdToFind) return portfolioTree;
  for (const component of portfolioTree.components) {
    // if the component is the one we're looking for, return the component itself
    if (component.portfolioTreeId === portfolioTreeIdToFind) return component;
    if (isPortfolioTreeSubportfolio(component)) {
      const subPortfolio = findTreeComponent(component, portfolioTreeIdToFind);
      if (subPortfolio) return subPortfolio;
    }
  }
};

/**
 * Previously, this function would go DEEP first, meaning that even if the strategy you were looking for was on the first
 * tree layer, if there was a subportfolio before that strategy, it would go into that subportfolio first and try to
 * find a match. If it did find a match, it would return the strategy two levels down, instead of the strategy
 * on the first layer.
 *
 * This leads to some unexpected behavior with the hoverability of strategies that are duplicated, so I am changing
 * it to exhaustively search a layer before going another level down.
 */
export const findTreeComponentByStrategyCode = (
  portfolioTree: IPortfolioTreeSubportfolio,
  strategyCode: string,
): IPortfolioTreeStrategy | undefined => {
  for (const component of portfolioTree.components.filter(isPortfolioTreeStrategy)) {
    if ((component.strategy?.code ?? component.reference) === strategyCode) return component;
  }

  for (const component of portfolioTree.components) {
    if (isPortfolioTreeSubportfolio(component)) {
      const strategy = findTreeComponentByStrategyCode(component, strategyCode);
      if (strategy) return strategy;
    }
  }
};

export const generateComponents = (
  rows: ISidebarRow[],
  tree: IPortfolioTree,
): (IPortfolioTreeSubportfolio | IPortfolioTreeStrategy)[] => {
  return rows
    .map((o): IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | undefined => {
      const component = findTreeComponent(tree.portfolioTree, o.portfolioTreeId);
      if (!component) return;
      const cloned = cloneDeep(component);
      if (isPortfolioTreeStrategy(cloned)) return cloned;
      cloned.components = generateComponents(o.children, tree);
      return cloned;
    })
    .filter((o): o is IPortfolioTreeSubportfolio | IPortfolioTreeStrategy => o !== undefined);
};

export const findSidebarRowById = ({
  portfolioTreeId,
  arr,
}: {
  portfolioTreeId: string;
  arr: ISidebarRow[];
}): ISidebarRow | null => {
  for (const row of arr) {
    if (row.portfolioTreeId === portfolioTreeId) {
      return row;
    }
    if (row.children.length) {
      const retval = findSidebarRowById({ portfolioTreeId, arr: row.children });
      if (retval !== null) return retval;
    }
  }

  return null;
};

export const suspendAll = async (
  portfolioTree: IPortfolioTreeSubportfolio,
  portfolioUnderFocusId: string,
): Promise<void> => {
  for (const component of portfolioTree.components) {
    if (component.suspended === Status.ACTIVE) set(component, 'suspended', Status.SUSPENDED);
    if (isPortfolioTreeSubportfolio(component)) {
      suspendAll(component, portfolioUnderFocusId);
    }
  }
};

/**
 * determines if strategy is inactive.
 * first, it checks if item is strategy. subportfolio are always active
 * isOnConstituentStep is used to determine if the strategy needs to be active on Constituent Risk page or not. this condition is
 * independent from Portfolio Construction page (i.e. suspendedForRisk vs suspended fields)
 * if status is greater than 1, it is inactive or soft deleted
 *
 * @param item
 */
export const isItemInactive = (
  item: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null | undefined,
  isOnConstituentStep?: boolean,
): boolean => {
  if (!item || item === null || !isPortfolioTreeStrategy(item)) return false;
  if (isOnConstituentStep) return false;
  return item.status > 1;
};

export const createSubportfolio = (
  name: string,
  masterPortfolioTree: IPortfolioTree | null,
  toCurrency?: Currency,
  fxType?: FxConversion,
  weightingType?: WeightingTypeConstants,
) => {
  if (!masterPortfolioTree) return;

  const uuid = uuidv4();
  const options: AllocationDTO = {
    weighting: {
      type: weightingType ?? WeightingTypeConstants.EQUAL, // or no weighting
      returnInterval: ReturnInterval.DAILY,
    },
    rebalancing: {
      type: RebalancingTypeConstants.QUARTERLY,
      month: RebalancingQuarterlyMonths.MAR,
      date: RebalancingMonthlyMonthlyReturnInterval['LAST_DAY'],
    },
    leverage: {
      type: LeverageTypeConstants.TARGET_EXPOSURE,
      targetExposure: 100,
    },
  };
  set(masterPortfolioTree.allocation, uuid, options);

  const tree = createBlankPortfolioTree(name, {
    portfolioTreeId: uuid,
    toCurrency,
    fxType,
  });

  return tree;
};

/**
 * Creates the list of properties needed for a new subportfolio added client side
 */
export const createBlankPortfolioTree = (
  name: string,
  options?: Partial<IPortfolioTreeSubportfolio>,
): IPortfolioTreeSubportfolio => {
  return {
    portfolioTreeId: crypto.randomUUID(),
    type: PortfolioType.PORTFOLIO,
    suspended: Status.ACTIVE,
    status: Status.ACTIVE,
    name,
    components: [],
    weighting: 0,
    assetClass: AssetClassConstants.MULTI_ASSETS,
    region: Region.GLOBAL,
    factor: FactorConstants.MULTI,
    style: StyleConstants.BETA,
    fxType: FxConversion.NOT_CONVERTED,
    toCurrency: Currency.USD,
    isInherited: true,
    description: '',
    ...options,
  };
};

/**
 * Creates the list of properties needed for a new equity basket subportfolio added client side
 */
export const createBlankBasketTree = (
  name: string,
  options?: Partial<IPortfolioTreeSubportfolio>,
): IPortfolioTreeSubportfolio => {
  return {
    portfolioTreeId: crypto.randomUUID(),
    type: PortfolioType.EQUITY_BASKET,
    suspended: Status.ACTIVE,
    status: Status.ACTIVE,
    name,
    components: [],
    weighting: 0,
    assetClass: AssetClassConstants.EQUITY,
    region: Region.GLOBAL,
    factor: FactorConstants.MULTI,
    style: StyleConstants.SMART_BETA,
    fxType: FxConversion.UNHEDGED,
    toCurrency: Currency.USD,
    isInherited: true,
    description: '',
    ...options,
  };
};

/**
 * Creates the list of properties needed for a new portfolio added client side
 */
export const createBlankPortfolio = (): IPortfolioTree => {
  return {
    allocation: {},
    blmViewId: null,
    draftOf: null,
    isBenchmark: false,
    isDemo: false,
    isRpiPortfolio: false,
    oldPortfolioId: -1,
    originalSlug: '',
    portfolioId: crypto.randomUUID(),
    portfolioTree: createBlankPortfolioTree('', {}),
    slug: '',
    slugOrCode: null,
    teamId: 0,
    createdAt: '',
    createdBy: 0,
    deletedAt: '',
    updatedAt: '',
    updatedBy: 0,
    parameters: null,
  };
};

// Todo: add a new API to fetch the strategy static info specifically for portfolio trees.
// For time being, just make sure the types look correct.
function convertToIPortfolioTreeStrategyStaticInfo(
  o: IStrategy | AddStrategySubset | StrategyItemResponseDTO,
): IPortfolioTreeStrategyStaticInfo {
  if (!('type' in o)) {
    return {
      id: o.id,
      code: o.code,
      shortName: o.shortName,
    } as IPortfolioTreeStrategyStaticInfo;
  }

  return {
    id: o.id,
    code: o.code,
    trackType: o.trackType,
    type: o.type,
    shortName: o.shortName,
    longName: o.longName,
    assetClass: o.assetClass,
    factor: o.factor,
    style: o.style,
    returnType: null,
    currency: o.currency,
    internalReference: o.internalReference ?? null,
    isin: o.isin ?? [],
    morningStarId: ('morningStarId' in o && o.morningStarId) || [],
    ric: ('ric' in o && o.ric) || [],
    ticker: ('ticker' in o && o.ticker) || [],
    sedol: o.sedol ?? [],
    historyStartDate: o.historyStartDate ?? '',
    effectiveHistoryStartDate: o.historyStartDate ?? '',
    inceptionDate: o.inceptionDate ?? '',
    provider: o.provider ?? '',
    region: o.region,
  };
}

/**
 * Creates the list of properties needed for a new strategy added client side
 * @param o TimeSeries data
 */
export const createBlankPortfolioTreeStrategy = (o: AddStrategySubset): IPortfolioTreeStrategy => {
  const { masterPortfolioTree } = usePortfolioTree();

  if ('isProxy' in o) {
    return {
      portfolioTreeId: crypto.randomUUID(),
      type: IPortfolioTreeChildType.TIMESERIES,
      crossReferenceId: o.code,
      suspended: Status.ACTIVE,
      status: Status.ACTIVE,
      suspendedForRisk: Status.ACTIVE,
      strategy: convertToIPortfolioTreeStrategyStaticInfo(o),
      weighting: 0,
      toCurrency: masterPortfolioTree.value ? masterPortfolioTree.value.portfolioTree.toCurrency : Currency.USD,
      fxType: masterPortfolioTree.value ? masterPortfolioTree.value.portfolioTree.fxType : FxConversion.NOT_CONVERTED,
      isInherited: true,
    };
  }
  return {
    portfolioTreeId: crypto.randomUUID(),
    type: IPortfolioTreeChildType.TIMESERIES,
    timeseriesId: o.id,
    suspended: Status.ACTIVE,
    status: Status.ACTIVE,
    suspendedForRisk: Status.ACTIVE,
    strategy: convertToIPortfolioTreeStrategyStaticInfo(o),
    weighting: 0,
    toCurrency: masterPortfolioTree.value ? masterPortfolioTree.value.portfolioTree.toCurrency : Currency.USD,
    fxType: masterPortfolioTree.value ? masterPortfolioTree.value.portfolioTree.fxType : FxConversion.NOT_CONVERTED,
    isInherited: true,
  };
};

export const isConstituentPortfolioFn = (portfolioTree: MaybeRef<IPortfolioTree | null>): ComputedRef<boolean> => {
  return computed(() => {
    const unwrapped = unref(portfolioTree);
    return unwrapped !== null && unwrapped.portfolioTree.type === PortfolioType.CONSTITUENT;
  });
};

export const isStartFreshPortfolioFn = (portfolioTree: ComputedRef<IPortfolioTree | null>): ComputedRef<boolean> => {
  return computed(
    () => portfolioTree.value !== null && portfolioTree.value.portfolioTree.type === PortfolioType.START_FRESH,
  );
};

export const isEquityBasketPortfolioFn = (portfolioTree: ComputedRef<IPortfolioTree | null>): ComputedRef<boolean> => {
  return computed(
    () => portfolioTree.value !== null && portfolioTree.value.portfolioTree.type === PortfolioType.EQUITY_BASKET,
  );
};

export const getShouldShowInitialWeightsFn = (portfolioTree: ComputedRef<IPortfolioTree | null>) => {
  const availableSnapshots = computed(() => PortfolioModule.availableSnapshots);
  const availableBacktests = computed(() => PortfolioModule.availableBacktests);
  const isStartFreshPortfolio = isStartFreshPortfolioFn(portfolioTree);
  const isConstituentPortfolio = isConstituentPortfolioFn(portfolioTree);

  return computed(() => {
    return (
      (availableSnapshots.value.length || availableBacktests.value?.length) &&
      (isStartFreshPortfolio.value || isConstituentPortfolio.value)
    );
  });
};

/**
 * determines if the selected portfolio has a custom weighting type or not
 * used in PortfolioWeightToolbar and StaticDataModal
 *
 * @param portfolio portfolio tree with allocation data
 */
export const isPortfolioTreeCustomFn = (portfolio: IPortfolioTree | null): boolean => {
  if (!portfolio || !portfolio.allocation) return false;
  return portfolio.allocation[portfolio.portfolioId].weighting.type === WeightingTypeConstants.CUSTOM;
};

/**
 * determines if portfolio/strategy has a pointerSlug or if it's parent has a pointerSlug
 * used in SidebarRow and StaticDataModal
 *
 * @param item any portfolio tree item or strategy with or without pointerSlug
 * @param isParentPointerPortfolio boolean to determine if parent is a pointer portfolio
 */
export const isPortfolioTreePointerFn = (
  item: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null | undefined,
  isParentPointerPortfolio?: boolean,
): boolean => {
  if (!item) return false;
  if (isPortfolioTreeSubportfolio(item)) {
    if (item.pointerSlug) {
      return true;
    }
  }
  if (isParentPointerPortfolio) return isParentPointerPortfolio;
  return false;
};

const { getStrategyMask } = useStrategyMask();

export const getNameById = (
  portfolioId: string,
  data: (IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | IFactsheetDatum)[],
) => {
  const datum = data.find((datum) => {
    if ('id' in datum) return datum.id === portfolioId;
    if ('portfolioId' in datum) return datum.portfolioId === portfolioId;
    if ('portfolioTreeId' in datum) return datum.portfolioTreeId === portfolioId;
    datum satisfies never;
  });
  if (!datum) return;
  return getStrategyMask(datum);
};

export const createRows = (portfolioTreeItem: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy): ISidebarRow => {
  if (isPortfolioTreeStrategy(portfolioTreeItem)) {
    return {
      portfolioTreeId: portfolioTreeItem.portfolioTreeId,
      code: portfolioTreeItem.strategy?.code,
      children: [],
    };
  }
  return {
    portfolioTreeId: portfolioTreeItem.portfolioTreeId,
    children: portfolioTreeItem.components.map((component) => createRows(component)),
  };
};

/**
 * Flatten the portfolio tree to a list of components
 */
export const flatten = (tree: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy) => {
  const result: (IPortfolioTreeSubportfolio | IPortfolioTreeStrategy)[] = [tree];

  if ('components' in tree) {
    for (const component of tree.components) {
      result.push(...flatten(component));
    }
  }

  return result;
};

export const getTrackQuery = (period?: Ref<Period>) => {
  const { period: defaultPeriod, returnInterval } = usePeriod();
  const { fxType, toCurrency } = useFxConversion();
  const { itemUnderAnalysis } = usePortfolioTree();
  const { isRegimeAnalysisActive, storeRegimeId } = useRegimeAnalysisState();

  return computed(() => {
    const query: IPortfolioTreeTracksQuery = {
      startDate: period ? period.value.fromDateString() : defaultPeriod.value.fromDateString(),
      endDate: period ? period.value.toDateString() : defaultPeriod.value.toDateString(),
      returnInterval: returnInterval.value,
      codes:
        itemUnderAnalysis.value && isPortfolioTreeStrategy(itemUnderAnalysis.value) && itemUnderAnalysis.value.strategy
          ? [itemUnderAnalysis.value.strategy.code]
          : [],
      treeIds:
        itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)
          ? [itemUnderAnalysis.value.portfolioTreeId]
          : [],
      blmId: undefined,
      portfolioTreeId: undefined,
      regimeId: isRegimeAnalysisActive.value && storeRegimeId.value !== null ? storeRegimeId.value : undefined,

      /** TODO: Remove this when we invalidate queries based on draft portfolio API Call.
       * This does not need to be sent to the API as the fx conversion is handled by the draft portfolio
       * But I am adding it to the query so that it can refetch this api based on the changes
       */
      fxType: fxType.value,
      toCurrency: toCurrency.value,
    };

    return query;
  });
};

export const getPortfolioRegimeData = (period?: Ref<Period>) => {
  const { draftSlug } = usePortfolioTreeDraftIdentifiers();
  const { isRegimeAnalysisActive, storeRegimeId } = useRegimeAnalysisState();
  const trackQuery = getTrackQuery(period);
  const portfolioTreeTracks = usePortfolioTreeTrackData(
    draftSlug,
    trackQuery,
    { enabled: isRegimeAnalysisActive },
    computed(() => null),
    storeRegimeId,
  );
  const portfolioTreeTracksData = computed(() => portfolioTreeTracks.data.value ?? []);

  const regimeOutput = computed(() => {
    return portfolioTreeTracksData.value.find((d) => !!d.regimeOutput)?.regimeOutput;
  });

  const data = computed(() => {
    return (
      /**
       * Because the dates for all these tracks are the same, we just need to grab the first one
       */
      regimeOutput.value?.regimes ?? []
    );
  });

  const hasError = computed(() => !regimeOutput.value);

  return {
    query: trackQuery,
    data,
    isLoading: portfolioTreeTracks.isLoadingOrFetching,
    hasError,
  };
};

export const getStrategyRegimeData = (
  period?: Ref<Period>,
  returnInterval?: Ref<ReturnInterval>,
  options: VQQueryOptions = {},
) => {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const { itemUnderAnalysis } = usePortfolioTree();
  const { period: defaultPeriod, returnInterval: defaultReturnInterval } = usePeriod();
  const { fxType, toCurrency } = useFxConversion();

  const { isRegimeAnalysisActive, storeRegimeId } = useRegimeAnalysisState();
  const trackQuery = computed((): ITrackQuery => {
    const periodToUse = period ?? defaultPeriod;
    const returnIntervalToUse = returnInterval ?? defaultReturnInterval;

    return {
      period: [periodToUse.value.abbrev],
      startDates: [periodToUse.value.fromDateString()],
      endDate: periodToUse.value.toDateString(),
      returnInterval: returnIntervalToUse.value,
      fxType: fxType.value,
      toCurrency: toCurrency.value,
      regimeId: storeRegimeId.value,
    };
  });
  const strategyTrack = useStrategyTrack(indexUniqueIdentifier, trackQuery, {
    enabled: chainEnabled(
      options.enabled,
      isRegimeAnalysisActive,
      computed(() => {
        return itemUnderAnalysis.value !== null && isStrategy(itemUnderAnalysis.value);
      }),
    ),
  });

  const regimeOutput = computed(() => {
    return strategyTrack.data.value?.regimeOutput;
  });

  const data = computed(() => {
    return (
      /**
       * Because the dates for all these tracks are the same, we just need to grab the first one
       */
      regimeOutput.value?.regimes ?? []
    );
  });

  const hasError = computed(() => !regimeOutput.value);

  return {
    query: trackQuery,
    data,
    isLoading: strategyTrack.isLoadingOrFetching,
    hasError,
  };
};

/**
 * prevent an essentially duplicate call from going out by ignoring the portfolioTreeId when
 * the master portfolio tree is the item under analysis.
 *
 * Also prevents using the wrong slug when the URL has changed but the masterPortfolioTree has not yet been updated
 *
 * Should be fed the ORIGINAL slug of the draft!
 *
 * NOTE: not all queries support returning undefined, so pass `allowUndefined: false` if you need this id no matter what
 */
export const getItemUnderAnalysisPortfolioTreeId = ({
  originalSlug,
  allowUndefined = true,
}: {
  originalSlug: Ref<string | null>;
  allowUndefined?: boolean;
}) => {
  const { itemUnderAnalysis, masterPortfolioTree } = usePortfolioTree();

  return computed((): string | undefined => {
    if (
      !itemUnderAnalysis.value ||
      isStrategy(itemUnderAnalysis.value) ||
      // prevent calling the wrong slug when the URL has changed but the masterPortfolioTree has not yet been updated
      masterPortfolioTree.value?.originalSlug !== originalSlug.value
    ) {
      return undefined;
    }

    if (
      masterPortfolioTree.value &&
      itemUnderAnalysis.value.portfolioTreeId === masterPortfolioTree.value.portfolioId &&
      allowUndefined
    ) {
      return undefined;
    }

    return itemUnderAnalysis.value.portfolioTreeId;
  });
};

export const setPortfolioTreeUnderAnalysisOnDraftChange = (
  draft: Ref<IPortfolioTree | undefined>,
  suppressErrors?: Ref<boolean>,
  shouldPin?: boolean,
) => {
  const { pinItemUnderAnalysis } = usePinned();
  const route = useRouteRef();
  const { isPortfolioPage } = useRouteChecks(route);

  watch(
    draft,
    (newVal, oldVal) => {
      if (isEqual(newVal, oldVal) || !newVal) return;

      if (!isPortfolioPage.value) {
        PortfolioModule.SetPortfolioTreeUnderAnalysis({
          portfolioTree: null,
        });
        return;
      }

      PortfolioModule.SetPortfolioTreeUnderAnalysis({
        portfolioTree: newVal,
        suppressErrors: suppressErrors?.value,
      });
      if (shouldPin) pinItemUnderAnalysis({ selfKey: newVal.portfolioId });
    },
    { immediate: true },
  );
};
